<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("camera.title", 1)
          }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Nombre -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('vxg_camera.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Master Account -->
          <validation-provider
            #default="validationContext"
            name="vxg_master_account"
            rules="required"
          >
            <b-form-group
              :label="$tc('vxg_master_account.title', 1)"
              label-for="vxg_master_account"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_vxg_master_account"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vxgMasterAccountOptions"
                :reduce="(val) => val.value"
                input-id="vxg_master_account"
                :clearable="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Instalacion -->
          <validation-provider #default="validationContext" name="unit">
            <b-form-group
              data-cy="facility"
              class="text-capitalize"
              :label="$tc('unit.title', 2)"
              label-for="id_unit"
              :state="getValidationState(validationContext)"
            >
              <select-paginate
                v-model="itemData.id_unit"
                :items="unitOptions"
                input-id="id_unit"
                :multiple="true"
                @change="updateDeviceTags"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Centro -->
          <validation-provider #default="validationContext" name="center">
            <b-form-group
              data-cy="center"
              class="text-capitalize"
              :label="$tc('centers.title')"
              label-for="id_centro"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_center"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="centerOptions"
                :reduce="(val) => val.value"
                input-id="id_center"
                @input="changeCenter"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sector -->
          <validation-provider #default="validationContext" name="sector">
            <b-form-group
              data-cy="sector"
              class="text-capitalize"
              :label="$tc('sectors.title')"
              label-for="id_sector"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_sector"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sectorOptions"
                :reduce="(val) => val.value"
                input-id="id_sector"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Omniview reseller -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.reseller')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.reseller')"
              label-for="reseller"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_omniview_reseller"
                :options="resellerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="reseller"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.type')"
              label-for="id_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="live_stream"
            v-if="!isAdd"
            >
            <b-form-group
              class="text-capitalize"
              :label="$t('vxg_camera.live_stream')"
              label-for="live_stream"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.live_stream"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="itemData.video_stream_options"
                :clearable="false"
                input-id="live_stream"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="recording_stream"
            v-if="!isAdd"
            >
            <b-form-group
              class="text-capitalize"
              :label="$t('vxg_camera.recording_stream')"
              label-for="recording_stream"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.recording_stream"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="itemData.video_stream_options"
                :clearable="false"
                input-id="recording_stream"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- delayed_record_minutes -->
          <validation-provider
            #default="validationContext"
            name="delayed_record_minutes"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.previous_time_of_prealarma')"
              label-for="delayed_record_minutes"
            >
              <b-form-timepicker
                id="delayed_record_minutes"
                v-model="itemData.delayed_record_minutes"
                :state="getValidationState(validationContext)"
                trim
                show-seconds
                placeholder=""
                reset-button
              />
            </b-form-group>
          </validation-provider>

          <!-- forward_record_minutes -->
          <validation-provider
            #default="validationContext"
            name="forward_record_minutes"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.later_time_of_prealarma')"
              label-for="forward_record_minutes"
            >
              <b-form-timepicker
                id="forward_record_minutes"
                v-model="itemData.forward_record_minutes"
                :state="getValidationState(validationContext)"
                trim
                show-seconds
                placeholder=""
                reset-button
              />
            </b-form-group>
          </validation-provider>

          <!-- Tags -->
          <validation-provider #default="validationContext" name="Tag">
            <b-form-group
              class="text-capitalize"
              label="Tag"
              label-for="id_device_tag"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_device_tag"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceTagsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_device_tag"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- rec_mode -->
          <validation-provider name="rec_mode" rules="required">
            <b-form-checkbox
              id="rec_mode"
              v-model="itemData.rec_mode"
              name="rec_mode"
              value="1"
              unchecked-value="0"
              class="my-1"
              switch
            >
              {{ $t("vxg_camera.rec_mode") }}
            </b-form-checkbox>
          </validation-provider>

          <validation-provider name="rtsp_available" rules="required" vid="rtsp_available">
            <b-form-checkbox
              id="rtsp_available"
              v-model="itemData.rtsp_available"
              name="rtsp_available"
              value="1"
              class="my-1 text-capitalize"
              unchecked-value="0"
              switch
            >
              {{ $t("camera_proxy.rtsp_available") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              class="text-capitalize"
              @click="hide"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import SelectPaginate from "../../components/SelectPaginate.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    SelectPaginate,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    sectorOptions: {
      type: Array,
      required: true,
    },
    centerOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    vxgMasterAccountOptions: {
      type: Array,
      required: true,
    },
    resellerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils();
    const trans = {
      "messages.edit_save.successfully_created": t(
        "messages.edit_save.successfully_created"
      ),
      "messages.edit_save.updated_successfully": t(
        "messages.edit_save.updated_successfully"
      ),
    };
    const blankData = {
      id_unit: [],
      id_center: null,
      id_sector: null,
      id_type: null,
      name: "",
      streaming: 0,
      rec_mode: false,
      id_device_tag: null,
      id_vxg_master_account: null,
      rtsp_available: false,
      live_stream: '',
      recording_stream: '',
      id_omniview_reseller: null,
      delayed_record_minutes: '00:00:10',
      forward_record_minutes: '00:00:10'
    };

    const toast = useToast();
    const deviceTagsOptions = computed(() => {
      return store.getters["tenant/getDeviceTags"].map((item) => ({
        label: item.name,
        value: item.id,
      }));
    });

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      const params = {
        id_unit:
          itemData.value.id_unit.length > 0
            ? itemData.value.id_unit.map((unit) => unit.value)
            : null,
        id_center: itemData.value.id_center || null,
        id_sector: itemData.value.id_sector || null,
        id_type: itemData.value.id_type,
        name: itemData.value.name,
        rec_mode: itemData.value.rec_mode == "on" ? true : false,
        delayed_record_minutes: itemData.value.delayed_record_minutes,
        forward_record_minutes: itemData.value.forward_record_minutes,
        id_device_tag: itemData.value.id_device_tag,
        id_vxg_master_account: itemData.value.id_vxg_master_account,
        id_omniview_reseller: itemData.value.id_omniview_reseller,
        rtsp_available: itemData.value.rtsp_available,
        live_stream: itemData.value.live_stream,
        recording_stream: itemData.value.recording_stream,
      };

      if (props.isAdd) {
        dispatch = store.dispatch("vxg_camera/add", params);
      } else {
        const req = {
          id: itemData.value.id,
          params,
        };
        dispatch = store.dispatch("vxg_camera/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: props.isAdd
                  ? trans["messages.edit_save.successfully_created"]
                  : trans["messages.edit_save.updated_successfully"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const changeCenter = () => {
      store
        .dispatch("sector/getAll", {
          id_center: [itemData.value.id_center],
        })
        .then(() => {
          const sector = props.sectorOptions.filter(
            (item) => item.value == itemData.value.id_sector
          );

          if (sector.length == 0) itemData.value.id_sector = "";
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const updateDeviceTags = () => {
      if (itemData.value.id_unit) {
        const idTenants = itemData.value.id_unit.map((unit) => unit.id_tenant);
        store.dispatch("tenant/getDeviceTagsByTenantIds", { id_tenants: idTenants });
      }
    };

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;

        itemData.value.id_omniview_reseller = props.itemEdit.camera.id_omniview_reseller;

        itemData.value.id_unit = props.itemEdit.camera.units.map((value) => ({
          value: value.id,
          label: value.description,
          id_tenant: value.router.id_tenant,
        }));

        itemData.value.id_device_tag = props.itemEdit.camera.id_device_tag;

        if (props.itemEdit.camera_type) {
          itemData.value.id_type = props.itemEdit.camera_type.id;
        }

        updateDeviceTags();
        changeCenter();
      }
    };

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      updateDeviceTags,
      deviceTagsOptions,
      resetForm,
      changeCenter,
    };
  },
};
</script>
